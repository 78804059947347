<template>
  <div>
    <el-page-header @back="goBack" content="新增页面"> </el-page-header>
    <div class="new">
      <el-form label-width="80px">
        <el-form-item label="敏感词">
          <el-input v-model="words" class="text"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="addBlog">立即添加</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { QUERY, UPDATE, DELETE, INSERT } from "@/services/dao.js";
export default {
  data() {
    return {
      words: "",
    };
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    // 点击新增文章的确定按钮（确定新增信息） 将所增信息提交给后台
    async addBlog() {
      if (this.words == "") {
        this.$message.error("新增内容不准为空");
      } else {
        let data = await INSERT(
          "POST",
          "",
          ' insert_TtSensitivewords(objects: {words: "' +
            this.words +
            '"}) {     affected_rows   }'
        );
        console.log(data);
        if (data.data.insert_TtSensitivewords.affected_rows > 0) {
          this.$message({
            message: '添加成功',
            type: 'success'
            });
        }
      }
    },
  },
};
</script>

<style>
.new {
  margin-top: 20px;
}
.text {
  width: 500px;
  font-size: 20px;
}
</style>